// stylelint-disable selector-class-pattern
//  Orange Boosted with Bootstrap
//         Orange Icons
//         Copyright (C) 2016 Orange SA All rights reserved
//         See NOTICE.txt for more information

@import "functions";
@import "variables";
@import "mixins";
@import "mixins/orange-icons";

@font-face {
  font-family: icon-orange;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-display: block;
  src: url("#{$icon-font-path}icon-orange.woff2") format("woff2"), url("#{$icon-font-path}icon-orange.woff") format("woff");
}

.icon-checkbox-tick,
.icon-2for1cinematicket,
.icon-3g,
.icon-4g,
.icon-4g-cam,
.icon-4G-Cam--Live-80,
.icon-4G-Cam-Camera-80,
.icon-4G-Cam-Inbox80,
.icon-4G-Cam-Outbox-80,
.icon-4G-Cam-Rec-80,
.icon-4g-plus,
.icon-4G-Cam-Compact,
.icon-1013-Reseau,
.icon-about-event,
.icon-Accessability,
.icon-accessibility-cognition,
.icon-accessibility-dexterity,
.icon-accessibility-hearing,
.icon-accessibility-mobility,
.icon-accessibility-speech,
.icon-accessibility-vision,
.icon-accessory-headphones,
.icon-accessory-shop,
.icon-Add,
.icon-add-more,
.icon-Add-person,
.icon-address-book,
.icon-administrator,
.icon-Adultcode,
.icon-Adult,
.icon-advertising,
.icon-aeroplane,
.icon-Age-rating-3,
.icon-Age-rating-4,
.icon-Age-rating-7,
.icon-Age-rating-9,
.icon-Age-rating-12,
.icon-Age-rating-16,
.icon-Age-rating-17,
.icon-Age-rating-18,
.icon-age-rating-mono-3,
.icon-age-rating-mono-4,
.icon-age-rating-mono-7,
.icon-age-rating-mono-9,
.icon-age-rating-mono-12,
.icon-age-rating-mono-16,
.icon-age-rating-mono-17,
.icon-age-rating-mono-18,
.icon-agriculture,
.icon-airbox-auto,
.icon-alarm-clock,
.icon-alarm-off,
.icon-Alias-numbers,
.icon-all-channels,
.icon-antenna,
.icon-anti-spam,
.icon-antivirus,
.icon-Apartment-building,
.icon-Apps,
.icon-Apps-disabled,
.icon-Archive,
.icon-arrow-down,
.icon-arrow-up,
.icon-arrow-next,
.icon-arrow-previous,
.icon-Art,
.icon-assistance,
.icon-attachment,
.icon-Auditorium,
.icon-avatar,
.icon-Baby,
.icon-backup,
.icon-banking,
.icon-battery-1-bar,
.icon-battery-2-bar,
.icon-battery-charging,
.icon-battery-empty,
.icon-battery-full,
.icon-battery-full-2,
.icon-Betting,
.icon-bill-euro,
.icon-billboard,
.icon-birthday,
.icon-bluetooth,
.icon-Book-award,
.icon-book-download,
.icon-bookmark,
.icon-bookmark-smart,
.icon-Boxes,
.icon-broadcast,
.icon-burst-mode,
.icon-Business-everywhere,
.icon-business-invoices,
.icon-business-lounge,
.icon-business-telephonie,
.icon-buy,
.icon-buy-back-euro,
.icon-buzz,
.icon-Calculator,
.icon-calendaralarm,
.icon-calendar-30,
.icon-calendar-day,
.icon-Calendar-fixtures-results,
.icon-Calendar-football-match,
.icon-calendar-month,
.icon-calendar-week,
.icon-call,
.icon-call-forward,
.icon-call-invite,
.icon-call-log,
.icon-camera,
.icon-Car-park,
.icon-Car-pooling,
.icon-Casting,
.icon-Categories,
.icon-CD,
.icon-change-wallpaper,
.icon-Change-wallpaper-1,
.icon-charger,
.icon-check-your-balance,
.icon-Child,
.icon-child-protection,
.icon-Children-channel,
.icon-cineday-silhouette,
.icon-clock,
.icon-cloud-backup,
.icon-Coat-hanger,
.icon-Collective-class-training,
.icon-Colour-Call,
.icon-Comedy-channel,
.icon-Comic,
.icon-Comments,
.icon-commercial-representitive,
.icon-community-sms,
.icon-Concierge-bell,
.icon-conference-call,
.icon-connected-object,
.icon-connection,
.icon-construction,
.icon-contact-card,
.icon-control-centre,
.icon-control-mydata,
.icon-copy,
.icon-Courier,
.icon-CPU,
.icon-crane,
.icon-Crop,
.icon-Currency-brazilian-real,
.icon-currency-converter,
.icon-Currency-dollar,
.icon-currency-euro,
.icon-Currency-sterling,
.icon-Currency-yen,
.icon-data-protection,
.icon-data-services,
.icon-decoder,
.icon-Decrease-test-size,
.icon-Defibrillator,
.icon-delete,
.icon-device-desktop-computer,
.icon-device-desktop-computer-connected,
.icon-device-fixed-line-phone,
.icon-device-laptop-computer,
.icon-device-laptop-computer-connected,
.icon-device-smart-phone,
.icon-device-status,
.icon-device-tablet,
.icon-device-tablet-connected,
.icon-device-tv,
.icon-device-connected-printer,
.icon-device-manager,
.icon-device-managment-express,
.icon-dialler,
.icon-Digital-frame,
.icon-disable-apps,
.icon-Discovery-offer,
.icon-Discussion-author,
.icon-Discussion-more,
.icon-Doctor,
.icon-Doctors-bag,
.icon-Doctors-communicating,
.icon-Document-compose,
.icon-document-stack,
.icon-document-word,
.icon-document-zip,
.icon-Documentary,
.icon-done-modifier,
.icon-download,
.icon-draft,
.icon-dual-sim-packaging,
.icon-easy-internet,
.icon-ebook,
.icon-Eco-mode,
.icon-edashboard,
.icon-edit-draft,
.icon-edit-email,
.icon-edit-image,
.icon-Elderly-person,
.icon-electric-car,
.icon-electric-car-charging,
.icon-electricity-provider,
.icon-elevator,
.icon-email,
.icon-email-forwarded,
.icon-Email-replied,
.icon-emap,
.icon-emergency-firemen,
.icon-emergency-police,
.icon-emergency-sos,
.icon-emergency-service,
.icon-employees-1,
.icon-Empty-trash,
.icon-End-call,
.icon-Entertainment-channel,
.icon-epg,
.icon-erase,
.icon-error-severe,
.icon-Ethenet,
.icon-event-agenda,
.icon-event-organiser,
.icon-event-speaker,
.icon-Excel,
.icon-Expand-screen,
.icon-expert,
.icon-external-drive,
.icon-Factory,
.icon-family-place,
.icon-Fast-delivery,
.icon-Favorite-music,
.icon-favourite,
.icon-Featured,
.icon-filter,
.icon-Filter-effects,
.icon-finance,
.icon-fire,
.icon-Fire-extinguisher,
.icon-Firewall,
.icon-flexible-storage,
.icon-folder-camera,
.icon-folder-document,
.icon-folder-favourite,
.icon-folder-sd,
.icon-football,
.icon-Football-match,
.icon-Football-quiz,
.icon-Forward,
.icon-Forward-curved-arrow,
.icon-forward-the-number,
.icon-free-tag,
.icon-Free-up-space,
.icon-front-end-dashboard,
.icon-Full-screen-view,
.icon-Gallery,
.icon-games,
.icon-Gas-tank,
.icon-gaugecapacity,
.icon-gauge-counter,
.icon-gauge-rate,
.icon-gauge-mon-reseau,
.icon-generic-document,
.icon-geolocation,
.icon-hands-free,
.icon-HDMI-Card,
.icon-health,
.icon-health-monitor,
.icon-Health-patient,
.icon-Heart-rate,
.icon-Heartbeats,
.icon-help-guide,
.icon-history,
.icon-home,
.icon-Home-1,
.icon-home-alarm,
.icon-Homelive,
.icon-homescreen,
.icon-Horoscopes,
.icon-hospital,
.icon-hotel,
.icon-Hourglass,
.icon-Im-driving,
.icon-image,
.icon-image-file,
.icon-improvement,
.icon-inbox,
.icon-Incoming-call-curved-arrow,
.icon-Increase-test-size,
.icon-info,
.icon-Information-data,
.icon-internationaltv,
.icon-international-globe,
.icon-internet,
.icon-internet-browser,
.icon-Internet-security-desktop,
.icon-Internet-security-mobile,
.icon-Internet-security-password,
.icon-Internet-security-server,
.icon-internet-usage,
.icon-Interventions,
.icon-IT-infrastructure,
.icon-keeper,
.icon-keyboard,
.icon-Keypad,
.icon-La-Cle,
.icon-Language,
.icon-Latest,
.icon-Le-Bloc,
.icon-Leave-session,
.icon-lightbulb-sh,
.icon-ligue-1,
.icon-Link,
.icon-live,
.icon-livebox,
.icon-livebox-info,
.icon-livebox-phone,
.icon-location-pin-compass,
.icon-lock,
.icon-Lock-screen-settings,
.icon-logistics,
.icon-logout,
.icon-Maintenance-man,
.icon-manage-android,
.icon-managing-equipment,
.icon-Map,
.icon-map-pin,
.icon-Medal,
.icon-Medical-app,
.icon-Medical-app-phone,
.icon-medical-chart,
.icon-Medical-files,
.icon-menu,
.icon-Message-download,
.icon-messaging,
.icon-messaging-compose-sms,
.icon-messaging-unified,
.icon-messaging-untitled-compose,
.icon-microphone,
.icon-Microscope,
.icon-mini-games,
.icon-mirroring,
.icon-Missed-call-boxed-arrow,
.icon-Missed-call-curved-arrow,
.icon-mobile-applications,
.icon-Mobile-backup-restore,
.icon-mobile-box,
.icon-mobile-data-toggle,
.icon-mobile-insurance,
.icon-mobile-network-coverage,
.icon-mobile-security,
.icon-mobile-setup,
.icon-Modem,
.icon-modifier-add,
.icon-Modifier-delete,
.icon-Modifier-favorite,
.icon-modifier-new,
.icon-modifier-no-access,
.icon-modifier-synchro,
.icon-modifier-upload,
.icon-modifier-wizard,
.icon-Modifier-download,
.icon-mon-reseau,
.icon-money,
.icon-More-dots,
.icon-move-to-folder,
.icon-multi-sim,
.icon-multiple-timezone,
.icon-Music-artist,
.icon-music-file,
.icon-Music-genre,
.icon-music-player,
.icon-Mute-microphone,
.icon-my-account,
.icon-my-bill-uk,
.icon-my-boutique,
.icon-my-essential,
.icon-my-essential-tablet,
.icon-my-livebox,
.icon-my-media,
.icon-My-Office-Phone,
.icon-my-orange,
.icon-my-plug,
.icon-my-services,
.icon-my-travels,
.icon-Nature,
.icon-Network-add,
.icon-Network-livebox,
.icon-networks,
.icon-new,
.icon-newspaper,
.icon-nfc,
.icon-NFC-tag,
.icon-no-sim,
.icon-Notification,
.icon-Notification-Bell,
.icon-nouveau-contract-social,
.icon-numero-unique,
.icon-office,
.icon-oil-and-gas,
.icon-On-board-unit,
.icon-online-help,
.icon-orangewallet,
.icon-orange-assistant,
.icon-orange-cash,
.icon-orange-corporate,
.icon-Orange-Detente,
.icon-orange-events,
.icon-Orange-Experts-Class-Training,
.icon-Orange-Experts-Copy-Keep,
.icon-orange-experts-expert,
.icon-orange-experts-insurance,
.icon-Orange-Experts-One2One-Coach,
.icon-orange-experts-orange-business-consultant,
.icon-orange-experts-orange-startup-smartphone,
.icon-orange-experts-recycle,
.icon-orange-experts-repair,
.icon-orange-experts-tranquil,
.icon-Orange-Football-Club,
.icon-orange-foundation,
.icon-Orange-garden,
.icon-orange-gesture,
.icon-orange-jobs,
.icon-orange-messenger,
.icon-Orange-Messenger-1,
.icon-orange-money,
.icon-orange-Photography,
.icon-orange-pro,
.icon-orange-protect,
.icon-orange-radio,
.icon-orange-reflections,
.icon-Orange-rewards,
.icon-Orange-security-suite,
.icon-orange-travel,
.icon-orange-tv,
.icon-orange-wallet,
.icon-orange-wifi,
.icon-Orange-Guest,
.icon-organisation,
.icon-outbox,
.icon-outdoor-sports-mountain-biking,
.icon-outdoor-sports-skiing,
.icon-Package,
.icon-Package-content,
.icon-parabole-tv,
.icon-parental-control,
.icon-Parrasol,
.icon-pass-video,
.icon-Pause,
.icon-Payment-euro,
.icon-pda-device,
.icon-pdf-file,
.icon-Pencil,
.icon-personal-smartphone-trainer,
.icon-phone-advisor,
.icon-phone-fund,
.icon-phone-protection,
.icon-Photo-dialer,
.icon-picture-messaging,
.icon-Pills,
.icon-pioneering,
.icon-Play,
.icon-play-and-win,
.icon-playmode-random,
.icon-polls,
.icon-portfolio,
.icon-POS-payment,
.icon-power,
.icon-powerpoint-file,
.icon-presentation,
.icon-previous,
.icon-printer,
.icon-profileringtone,
.icon-public-transport,
.icon-publish,
.icon-Quirky-stories,
.icon-Quiz,
.icon-Radiography,
.icon-ratings-stars,
.icon-Real-time,
.icon-recommend,
.icon-record,
.icon-Record-1,
.icon-recycle-device,
.icon-Redo,
.icon-relationship,
.icon-reload,
.icon-remote-control,
.icon-Remote-control-1,
.icon-remove-modifier,
.icon-Replay-boxed-arrow,
.icon-Reply-boxed-arrow,
.icon-Reply-curved-arrow,
.icon-report,
.icon-responsible,
.icon-Restaurant,
.icon-restore-to-phone,
.icon-Restrictied-content,
.icon-rewards,
.icon-ringtones,
.icon-running,
.icon-Scales,
.icon-scan-ticket,
.icon-School,
.icon-Science,
.icon-Scissors,
.icon-sd-card,
.icon-SD-card-1,
.icon-search,
.icon-Send,
.icon-Send-to-person,
.icon-Server,
.icon-servers,
.icon-settings,
.icon-setup-and-repair,
.icon-setup-and-repair-cog,
.icon-setup-internet,
.icon-share,
.icon-Share-wifi,
.icon-shipping,
.icon-Shop,
.icon-shop-pin,
.icon-shop-subscriptions,
.icon-Shopping,
.icon-Show-front-back-camera,
.icon-Shower,
.icon-showroom-mobile-pro,
.icon-signal-1-5,
.icon-signal-2-5,
.icon-signal-3-5,
.icon-signal-4-5,
.icon-signal-5-5,
.icon-signal-boost,
.icon-Silent,
.icon-sim,
.icon-simple-mode,
.icon-Simplicity,
.icon-Skip-backward,
.icon-Skip-forward,
.icon-Sleep,
.icon-Smart-electric-meter,
.icon-Smart-heating-switch,
.icon-smart-home,
.icon-smart-lightswitch,
.icon-smart-powerstation,
.icon-Smart-Sensor,
.icon-smart-tap,
.icon-smart-watch,
.icon-social-network,
.icon-software-update,
.icon-Sport-channel,
.icon-Start-services,
.icon-startup-wizard,
.icon-stocks,
.icon-stop-secret,
.icon-Stop-video,
.icon-Storage-cloud,
.icon-store-locator,
.icon-Street,
.icon-Stretcher,
.icon-subscription-package,
.icon-Sudoku,
.icon-support,
.icon-surveillance-camera,
.icon-Syncronise,
.icon-target-location,
.icon-tarif,
.icon-tariff,
.icon-task-list,
.icon-Taxi,
.icon-team-connect,
.icon-temperature,
.icon-terms-conditions,
.icon-text-alerts,
.icon-Thumb-down,
.icon-Thumb-up,
.icon-tick,
.icon-tick-circle,
.icon-tickets,
.icon-Top-10,
.icon-top-up-dollar,
.icon-top-up-euro,
.icon-top-up-uk-bank,
.icon-touch-screen,
.icon-town,
.icon-townhall,
.icon-track-your-delivery,
.icon-track-your-order,
.icon-Trading,
.icon-trash,
.icon-tv-plus,
.icon-TV-series,
.icon-Undo,
.icon-unique,
.icon-unknown-file,
.icon-unlimited-calls,
.icon-unlocked,
.icon-Unmissable,
.icon-upload,
.icon-USB,
.icon-usb-key,
.icon-Value,
.icon-vehicle,
.icon-video,
.icon-video-file,
.icon-video-phone,
.icon-video-meeting2,
.icon-Vinyl,
.icon-vip-contact,
.icon-virtual-gamepad,
.icon-visual-voicemail,
.icon-vod-portrait,
.icon-volte,
.icon-volume-high,
.icon-volume-low,
.icon-volume-mid,
.icon-volume-none,
.icon-vowifi,
.icon-vvm-message-read-full,
.icon-Warehouse,
.icon-Warningimportant,
.icon-Warning-important,
.icon-web-cam,
.icon-web-fax-widget,
.icon-Wholesale-retail,
.icon-Widget,
.icon-widget-tour,
.icon-wifi,
.icon-wifi-0,
.icon-wifi-1,
.icon-wifi-2,
.icon-wps-pairing,
.icon-WPT,
.icon-zoom-in,
.icon-zoom-out,
.icon-popout-tech,
.icon-frame-care,
.icon-frame-family,
.icon-frame-fun,
.icon-frame-home,
.icon-frame-money,
.icon-frame-on-the-move,
.icon-frame-tariff,
.icon-frame-tech,
.icon-frame-wellbeing,
.icon-frame-work,
.icon-popout-care,
.icon-popout-family,
.icon-popout-fun,
.icon-popout-home,
.icon-popout-money,
.icon-popout-on-the-move,
.icon-popout-tariff,
.icon-popout-wellbeing,
.icon-popout-work,
.icon-frame-connectivity,
.icon-frame-my-customers,
.icon-frame-performance,
.icon-frame-security,
.icon-frame-teamwork,
.icon-popout-connectivity,
.icon-popout-my-customers,
.icon-popout-performance,
.icon-popout-security,
.icon-popout-teamwork {
  // use !important to prevent issues with browser extensions that change fonts //
  font-family: icon-orange !important; // stylelint-disable-line declaration-no-important, font-family-no-missing-generic-family-keyword
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;

  // Better Font Rendering =========== //
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-popout-care,
.icon-popout-family,
.icon-popout-fun,
.icon-popout-home,
.icon-popout-money,
.icon-popout-on-the-move,
.icon-popout-tariff,
.icon-popout-tech,
.icon-popout-wellbeing,
.icon-popout-work,
.icon-frame-family,
.icon-frame-fun,
.icon-frame-home,
.icon-frame-money,
.icon-frame-on-the-move,
.icon-frame-tariff,
.icon-frame-tech,
.icon-frame-wellbeing,
.icon-frame-work,
.icon-frame-care,
.icon-frame-connectivity,
.icon-frame-my-customers,
.icon-frame-performance,
.icon-frame-security,
.icon-frame-teamwork,
.icon-popout-connectivity,
.icon-popout-my-customers,
.icon-popout-performance,
.icon-popout-security,
.icon-popout-teamwork {
  color: theme-color("primary");
}


.icon-checkbox-tick::before {
  content: "\eb77";
}
.icon-2for1cinematicket::before {
  content: "\e900";
}
.icon-3g::before {
  content: "\e901";
}
.icon-4g::before {
  content: "\e902";
}
.icon-4g-cam::before {
  content: "\e903";
}
.icon-4G-Cam--Live-80::before {
  content: "\e904";
}
.icon-4G-Cam-Camera-80::before {
  content: "\e905";
}
.icon-4G-Cam-Inbox80::before {
  content: "\e906";
}
.icon-4G-Cam-Outbox-80::before {
  content: "\e907";
}
.icon-4G-Cam-Rec-80::before {
  content: "\e908";
}
.icon-4g-plus::before {
  content: "\e909";
}
.icon-4G-Cam-Compact::before {
  content: "\e90a";
}
.icon-1013-Reseau::before {
  content: "\e90b";
}
.icon-about-event::before {
  content: "\e90c";
}
.icon-Accessability::before {
  content: "\e90d";
}
.icon-accessibility-cognition::before {
  content: "\e90e";
}
.icon-accessibility-dexterity::before {
  content: "\e90f";
}
.icon-accessibility-hearing::before {
  content: "\e910";
}
.icon-accessibility-mobility::before {
  content: "\e911";
}
.icon-accessibility-speech::before {
  content: "\e912";
}
.icon-accessibility-vision::before {
  content: "\e913";
}
.icon-accessory-headphones::before {
  content: "\e914";
}
.icon-accessory-shop::before {
  content: "\e915";
}
.icon-Add::before {
  content: "\e916";
}
.icon-add-more::before {
  content: "\e917";
}
.icon-Add-person::before {
  content: "\e918";
}
.icon-address-book::before {
  content: "\e919";
}
.icon-administrator::before {
  content: "\e91a";
}
.icon-Adultcode::before {
  content: "\e91b";
}
.icon-Adult::before {
  content: "\e91c";
}
.icon-advertising::before {
  content: "\e91d";
}
.icon-aeroplane::before {
  content: "\e91e";
}
.icon-Age-rating-3::before {
  content: "\e91f";
}
.icon-Age-rating-4::before {
  content: "\e920";
}
.icon-Age-rating-7::before {
  content: "\e921";
}
.icon-Age-rating-9::before {
  content: "\e922";
}
.icon-Age-rating-12::before {
  content: "\e923";
}
.icon-Age-rating-16::before {
  content: "\e924";
}
.icon-Age-rating-17::before {
  content: "\e925";
}
.icon-Age-rating-18::before {
  content: "\e926";
}
.icon-age-rating-mono-3::before {
  content: "\e927";
}
.icon-age-rating-mono-4::before {
  content: "\e928";
}
.icon-age-rating-mono-7::before {
  content: "\e929";
}
.icon-age-rating-mono-9::before {
  content: "\e92a";
}
.icon-age-rating-mono-12::before {
  content: "\e92b";
}
.icon-age-rating-mono-16::before {
  content: "\e92c";
}
.icon-age-rating-mono-17::before {
  content: "\e92d";
}
.icon-age-rating-mono-18::before {
  content: "\e92e";
}
.icon-agriculture::before {
  content: "\e92f";
}
.icon-airbox-auto::before {
  content: "\e930";
}
.icon-alarm-clock::before {
  content: "\e931";
}
.icon-alarm-off::before {
  content: "\e932";
}
.icon-Alias-numbers::before {
  content: "\e933";
}
.icon-all-channels::before {
  content: "\e934";
}
.icon-antenna::before {
  content: "\e935";
}
.icon-anti-spam::before {
  content: "\e936";
}
.icon-antivirus::before {
  content: "\e937";
}
.icon-Apartment-building::before {
  content: "\e938";
}
.icon-Apps::before {
  content: "\e939";
}
.icon-Apps-disabled::before {
  content: "\e93a";
}
.icon-Archive::before {
  content: "\e93b";
}
.icon-arrow-down::before {
  content: "\e93c";
}
.icon-arrow-up::before {
  content: "\e93d";
}
.icon-arrow-next::before {
  content: "\e93e";
}
.icon-arrow-previous::before {
  content: "\e93f";
}
.icon-Art::before {
  content: "\e940";
}
.icon-assistance::before {
  content: "\e941";
}
.icon-attachment::before {
  content: "\e942";
}
.icon-Auditorium::before {
  content: "\e943";
}
.icon-avatar::before {
  content: "\e944";
}
.icon-Baby::before {
  content: "\e945";
}
.icon-backup::before {
  content: "\e946";
}
.icon-banking::before {
  content: "\e947";
}
.icon-battery-1-bar::before {
  content: "\e948";
}
.icon-battery-2-bar::before {
  content: "\e949";
}
.icon-battery-charging::before {
  content: "\e94a";
}
.icon-battery-empty::before {
  content: "\e94b";
}
.icon-battery-full::before {
  content: "\e94c";
}
.icon-battery-full-2::before {
  content: "\e94d";
}
.icon-Betting::before {
  content: "\e94e";
}
.icon-bill-euro::before {
  content: "\e94f";
}
.icon-billboard::before {
  content: "\e950";
}
.icon-birthday::before {
  content: "\e951";
}
.icon-bluetooth::before {
  content: "\e952";
}
.icon-Book-award::before {
  content: "\e953";
}
.icon-book-download::before {
  content: "\e954";
}
.icon-bookmark::before {
  content: "\e955";
}
.icon-bookmark-smart::before {
  content: "\e956";
}
.icon-Boxes::before {
  content: "\e957";
}
.icon-broadcast::before {
  content: "\e958";
}
.icon-burst-mode::before {
  content: "\e959";
}
.icon-Business-everywhere::before {
  content: "\e95a";
}
.icon-business-invoices::before {
  content: "\e95b";
}
.icon-business-lounge::before {
  content: "\e95c";
}
.icon-business-telephonie::before {
  content: "\e95d";
}
.icon-buy::before {
  content: "\e95e";
}
.icon-buy-back-euro::before {
  content: "\e95f";
}
.icon-buzz::before {
  content: "\e960";
}
.icon-Calculator::before {
  content: "\e961";
}
.icon-calendaralarm::before {
  content: "\e962";
}
.icon-calendar-30::before {
  content: "\e963";
}
.icon-calendar-day::before {
  content: "\e964";
}
.icon-Calendar-fixtures-results::before {
  content: "\e965";
}
.icon-Calendar-football-match::before {
  content: "\e966";
}
.icon-calendar-month::before {
  content: "\e967";
}
.icon-calendar-week::before {
  content: "\e968";
}
.icon-call::before {
  content: "\e969";
}
.icon-call-forward::before {
  content: "\e96a";
}
.icon-call-invite::before {
  content: "\e96b";
}
.icon-call-log::before {
  content: "\e96c";
}
.icon-camera::before {
  content: "\e96d";
}
.icon-Car-park::before {
  content: "\e96e";
}
.icon-Car-pooling::before {
  content: "\e96f";
}
.icon-Casting::before {
  content: "\e970";
}
.icon-Categories::before {
  content: "\e971";
}
.icon-CD::before {
  content: "\e972";
}
.icon-change-wallpaper::before {
  content: "\e973";
}
.icon-Change-wallpaper-1::before {
  content: "\e974";
}
.icon-charger::before {
  content: "\e975";
}
.icon-check-your-balance::before {
  content: "\e976";
}
.icon-Child::before {
  content: "\e977";
}
.icon-child-protection::before {
  content: "\e978";
}
.icon-Children-channel::before {
  content: "\e979";
}
.icon-cineday-silhouette::before {
  content: "\e97a";
}
.icon-clock::before {
  content: "\e97b";
}
.icon-cloud-backup::before {
  content: "\e97c";
}
.icon-Coat-hanger::before {
  content: "\e97d";
}
.icon-Collective-class-training::before {
  content: "\e97e";
}
.icon-Colour-Call::before {
  content: "\e97f";
}
.icon-Comedy-channel::before {
  content: "\e980";
}
.icon-Comic::before {
  content: "\e981";
}
.icon-Comments::before {
  content: "\e982";
}
.icon-commercial-representitive::before {
  content: "\e983";
}
.icon-community-sms::before {
  content: "\e984";
}
.icon-Concierge-bell::before {
  content: "\e985";
}
.icon-conference-call::before {
  content: "\e986";
}
.icon-connected-object::before {
  content: "\e987";
}
.icon-connection::before {
  content: "\e988";
}
.icon-construction::before {
  content: "\e989";
}
.icon-contact-card::before {
  content: "\e98a";
}
.icon-control-centre::before {
  content: "\e98b";
}
.icon-control-mydata::before {
  content: "\e98c";
}
.icon-copy::before {
  content: "\e98d";
}
.icon-Courier::before {
  content: "\e98e";
}
.icon-CPU::before {
  content: "\e98f";
}
.icon-crane::before {
  content: "\e990";
}
.icon-Crop::before {
  content: "\e991";
}
.icon-Currency-brazilian-real::before {
  content: "\e992";
}
.icon-currency-converter::before {
  content: "\e993";
}
.icon-Currency-dollar::before {
  content: "\e994";
}
.icon-currency-euro::before {
  content: "\e995";
}
.icon-Currency-sterling::before {
  content: "\e996";
}
.icon-Currency-yen::before {
  content: "\e997";
}
.icon-data-protection::before {
  content: "\e998";
}
.icon-data-services::before {
  content: "\e999";
}
.icon-decoder::before {
  content: "\e99a";
}
.icon-Decrease-test-size::before {
  content: "\e99b";
}
.icon-Defibrillator::before {
  content: "\e99c";
}
.icon-delete::before {
  content: "\e99d";
}
.icon-device-desktop-computer::before {
  content: "\e99e";
}
.icon-device-desktop-computer-connected::before {
  content: "\e99f";
}
.icon-device-fixed-line-phone::before {
  content: "\e9a0";
}
.icon-device-laptop-computer::before {
  content: "\e9a1";
}
.icon-device-laptop-computer-connected::before {
  content: "\e9a2";
}
.icon-device-smart-phone::before {
  content: "\e9a3";
}
.icon-device-status::before {
  content: "\e9a4";
}
.icon-device-tablet::before {
  content: "\e9a5";
}
.icon-device-tablet-connected::before {
  content: "\e9a6";
}
.icon-device-tv::before {
  content: "\e9a7";
}
.icon-device-connected-printer::before {
  content: "\e9a8";
}
.icon-device-manager::before {
  content: "\e9a9";
}
.icon-device-managment-express::before {
  content: "\e9aa";
}
.icon-dialler::before {
  content: "\e9ab";
}
.icon-Digital-frame::before {
  content: "\e9ac";
}
.icon-disable-apps::before {
  content: "\e9ad";
}
.icon-Discovery-offer::before {
  content: "\e9ae";
}
.icon-Discussion-author::before {
  content: "\e9af";
}
.icon-Discussion-more::before {
  content: "\e9b0";
}
.icon-Doctor::before {
  content: "\e9b1";
}
.icon-Doctors-bag::before {
  content: "\e9b2";
}
.icon-Doctors-communicating::before {
  content: "\e9b3";
}
.icon-Document-compose::before {
  content: "\e9b4";
}
.icon-document-stack::before {
  content: "\e9b5";
}
.icon-document-word::before {
  content: "\e9b6";
}
.icon-document-zip::before {
  content: "\e9b7";
}
.icon-Documentary::before {
  content: "\e9b8";
}
.icon-done-modifier::before {
  content: "\e9b9";
}
.icon-download::before {
  content: "\e9ba";
}
.icon-draft::before {
  content: "\e9bb";
}
.icon-dual-sim-packaging::before {
  content: "\e9bc";
}
.icon-easy-internet::before {
  content: "\e9bd";
}
.icon-ebook::before {
  content: "\e9be";
}
.icon-Eco-mode::before {
  content: "\e9bf";
}
.icon-edashboard::before {
  content: "\e9c0";
}
.icon-edit-draft::before {
  content: "\e9c1";
}
.icon-edit-email::before {
  content: "\e9c2";
}
.icon-edit-image::before {
  content: "\e9c3";
}
.icon-Elderly-person::before {
  content: "\e9c4";
}
.icon-electric-car::before {
  content: "\e9c5";
}
.icon-electric-car-charging::before {
  content: "\e9c6";
}
.icon-electricity-provider::before {
  content: "\e9c7";
}
.icon-elevator::before {
  content: "\e9c8";
}
.icon-email::before {
  content: "\e9c9";
}
.icon-email-forwarded::before {
  content: "\e9ca";
}
.icon-Email-replied::before {
  content: "\e9cb";
}
.icon-emap::before {
  content: "\e9cc";
}
.icon-emergency-firemen::before {
  content: "\e9cd";
}
.icon-emergency-police::before {
  content: "\e9ce";
}
.icon-emergency-sos::before {
  content: "\e9cf";
}
.icon-emergency-service::before {
  content: "\e9d0";
}
.icon-employees-1::before {
  content: "\e9d1";
}
.icon-Empty-trash::before {
  content: "\e9d2";
}
.icon-End-call::before {
  content: "\e9d3";
}
.icon-Entertainment-channel::before {
  content: "\e9d4";
}
.icon-epg::before {
  content: "\e9d5";
}
.icon-erase::before {
  content: "\e9d6";
}
.icon-error-severe::before {
  content: "\e9d7";
}
.icon-Ethenet::before {
  content: "\e9d8";
}
.icon-event-agenda::before {
  content: "\e9d9";
}
.icon-event-organiser::before {
  content: "\e9da";
}
.icon-event-speaker::before {
  content: "\e9db";
}
.icon-Excel::before {
  content: "\e9dc";
}
.icon-Expand-screen::before {
  content: "\e9dd";
}
.icon-expert::before {
  content: "\e9de";
}
.icon-external-drive::before {
  content: "\e9df";
}
.icon-Factory::before {
  content: "\e9e0";
}
.icon-family-place::before {
  content: "\e9e1";
}
.icon-Fast-delivery::before {
  content: "\e9e2";
}
.icon-Favorite-music::before {
  content: "\e9e3";
}
.icon-favourite::before {
  content: "\e9e4";
}
.icon-Featured::before {
  content: "\e9e5";
}
.icon-filter::before {
  content: "\e9e6";
}
.icon-Filter-effects::before {
  content: "\e9e7";
}
.icon-finance::before {
  content: "\e9e8";
}
.icon-fire::before {
  content: "\e9e9";
}
.icon-Fire-extinguisher::before {
  content: "\e9ea";
}
.icon-Firewall::before {
  content: "\e9eb";
}
.icon-flexible-storage::before {
  content: "\e9ec";
}
.icon-folder-camera::before {
  content: "\e9ed";
}
.icon-folder-document::before {
  content: "\e9ee";
}
.icon-folder-favourite::before {
  content: "\e9ef";
}
.icon-folder-sd::before {
  content: "\e9f0";
}
.icon-football::before {
  content: "\e9f1";
}
.icon-Football-match::before {
  content: "\e9f2";
}
.icon-Football-quiz::before {
  content: "\e9f3";
}
.icon-Forward::before {
  content: "\e9f4";
}
.icon-Forward-curved-arrow::before {
  content: "\e9f5";
}
.icon-forward-the-number::before {
  content: "\e9f6";
}
.icon-free-tag::before {
  content: "\e9f7";
}
.icon-Free-up-space::before {
  content: "\e9f8";
}
.icon-front-end-dashboard::before {
  content: "\e9f9";
}
.icon-Full-screen-view::before {
  content: "\e9fa";
}
.icon-Gallery::before {
  content: "\e9fb";
}
.icon-games::before {
  content: "\e9fc";
}
.icon-Gas-tank::before {
  content: "\e9fd";
}
.icon-gaugecapacity::before {
  content: "\e9fe";
}
.icon-gauge-counter::before {
  content: "\e9ff";
}
.icon-gauge-rate::before {
  content: "\ea00";
}
.icon-gauge-mon-reseau::before {
  content: "\ea01";
}
.icon-generic-document::before {
  content: "\ea02";
}
.icon-geolocation::before {
  content: "\ea03";
}
.icon-hands-free::before {
  content: "\ea04";
}
.icon-HDMI-Card::before {
  content: "\ea05";
}
.icon-health::before {
  content: "\ea06";
}
.icon-health-monitor::before {
  content: "\ea07";
}
.icon-Health-patient::before {
  content: "\ea08";
}
.icon-Heart-rate::before {
  content: "\ea09";
}
.icon-Heartbeats::before {
  content: "\ea0a";
}
.icon-help-guide::before {
  content: "\ea0b";
}
.icon-history::before {
  content: "\ea0c";
}
.icon-home::before {
  content: "\ea0d";
}
.icon-Home-1::before {
  content: "\ea0e";
}
.icon-home-alarm::before {
  content: "\ea0f";
}
.icon-Homelive::before {
  content: "\ea10";
}
.icon-homescreen::before {
  content: "\ea11";
}
.icon-Horoscopes::before {
  content: "\ea12";
}
.icon-hospital::before {
  content: "\ea13";
}
.icon-hotel::before {
  content: "\ea14";
}
.icon-Hourglass::before {
  content: "\ea15";
}
.icon-Im-driving::before {
  content: "\ea16";
}
.icon-image::before {
  content: "\ea17";
}
.icon-image-file::before {
  content: "\ea18";
}
.icon-improvement::before {
  content: "\ea19";
}
.icon-inbox::before {
  content: "\ea1a";
}
.icon-Incoming-call-curved-arrow::before {
  content: "\ea1b";
}
.icon-Increase-test-size::before {
  content: "\ea1c";
}
.icon-info::before {
  content: "\ea1d";
}
.icon-Information-data::before {
  content: "\ea1e";
}
.icon-internationaltv::before {
  content: "\ea1f";
}
.icon-international-globe::before {
  content: "\ea20";
}
.icon-internet::before {
  content: "\ea21";
}
.icon-internet-browser::before {
  content: "\ea22";
}
.icon-Internet-security-desktop::before {
  content: "\ea23";
}
.icon-Internet-security-mobile::before {
  content: "\ea24";
}
.icon-Internet-security-password::before {
  content: "\ea25";
}
.icon-Internet-security-server::before {
  content: "\ea26";
}
.icon-internet-usage::before {
  content: "\ea27";
}
.icon-Interventions::before {
  content: "\ea28";
}
.icon-IT-infrastructure::before {
  content: "\ea29";
}
.icon-keeper::before {
  content: "\ea2a";
}
.icon-keyboard::before {
  content: "\ea2b";
}
.icon-Keypad::before {
  content: "\ea2c";
}
.icon-La-Cle::before {
  content: "\ea2d";
}
.icon-Language::before {
  content: "\ea2e";
}
.icon-Latest::before {
  content: "\ea2f";
}
.icon-Le-Bloc::before {
  content: "\ea30";
}
.icon-Leave-session::before {
  content: "\ea31";
}
.icon-lightbulb-sh::before {
  content: "\ea32";
}
.icon-ligue-1::before {
  content: "\ea33";
}
.icon-Link::before {
  content: "\ea34";
}
.icon-live::before {
  content: "\ea35";
}
.icon-livebox::before {
  content: "\ea36";
}
.icon-livebox-info::before {
  content: "\ea37";
}
.icon-livebox-phone::before {
  content: "\ea38";
}
.icon-location-pin-compass::before {
  content: "\ea39";
}
.icon-lock::before {
  content: "\ea3a";
}
.icon-Lock-screen-settings::before {
  content: "\ea3b";
}
.icon-logistics::before {
  content: "\ea3c";
}
.icon-logout::before {
  content: "\ea3d";
}
.icon-Maintenance-man::before {
  content: "\ea3e";
}
.icon-manage-android::before {
  content: "\ea3f";
}
.icon-managing-equipment::before {
  content: "\ea40";
}
.icon-Map::before {
  content: "\ea41";
}
.icon-map-pin::before {
  content: "\ea42";
}
.icon-Medal::before {
  content: "\ea43";
}
.icon-Medical-app::before {
  content: "\ea44";
}
.icon-Medical-app-phone::before {
  content: "\ea45";
}
.icon-medical-chart::before {
  content: "\ea46";
}
.icon-Medical-files::before {
  content: "\ea47";
}
.icon-menu::before {
  content: "\ea48";
}
.icon-Message-download::before {
  content: "\ea49";
}
.icon-messaging::before {
  content: "\ea4a";
}
.icon-messaging-compose-sms::before {
  content: "\ea4b";
}
.icon-messaging-unified::before {
  content: "\ea4c";
}
.icon-messaging-untitled-compose::before {
  content: "\ea4d";
}
.icon-microphone::before {
  content: "\ea4e";
}
.icon-Microscope::before {
  content: "\ea4f";
}
.icon-mini-games::before {
  content: "\ea50";
}
.icon-mirroring::before {
  content: "\ea51";
}
.icon-Missed-call-boxed-arrow::before {
  content: "\ea52";
}
.icon-Missed-call-curved-arrow::before {
  content: "\ea53";
}
.icon-mobile-applications::before {
  content: "\ea54";
}
.icon-Mobile-backup-restore::before {
  content: "\ea55";
}
.icon-mobile-box::before {
  content: "\ea56";
}
.icon-mobile-data-toggle::before {
  content: "\ea57";
}
.icon-mobile-insurance::before {
  content: "\ea58";
}
.icon-mobile-network-coverage::before {
  content: "\ea59";
}
.icon-mobile-security::before {
  content: "\ea5a";
}
.icon-mobile-setup::before {
  content: "\ea5b";
}
.icon-Modem::before {
  content: "\ea5c";
}
.icon-modifier-add::before {
  content: "\ea5d";
}
.icon-Modifier-delete::before {
  content: "\ea5e";
}
.icon-Modifier-favorite::before {
  content: "\ea5f";
}
.icon-modifier-new::before {
  content: "\ea60";
}
.icon-modifier-no-access::before {
  content: "\ea61";
}
.icon-modifier-synchro::before {
  content: "\ea62";
}
.icon-modifier-upload::before {
  content: "\ea63";
}
.icon-modifier-wizard::before {
  content: "\ea64";
}
.icon-Modifier-download::before {
  content: "\ea65";
}
.icon-mon-reseau::before {
  content: "\ea66";
}
.icon-money::before {
  content: "\ea67";
}
.icon-More-dots::before {
  content: "\ea68";
}
.icon-move-to-folder::before {
  content: "\ea69";
}
.icon-multi-sim::before {
  content: "\ea6a";
}
.icon-multiple-timezone::before {
  content: "\ea6b";
}
.icon-Music-artist::before {
  content: "\ea6c";
}
.icon-music-file::before {
  content: "\ea6d";
}
.icon-Music-genre::before {
  content: "\ea6e";
}
.icon-music-player::before {
  content: "\ea6f";
}
.icon-Mute-microphone::before {
  content: "\ea70";
}
.icon-my-account::before {
  content: "\ea71";
}
.icon-my-bill-uk::before {
  content: "\ea72";
}
.icon-my-boutique::before {
  content: "\ea73";
}
.icon-my-essential::before {
  content: "\ea74";
}
.icon-my-essential-tablet::before {
  content: "\ea75";
}
.icon-my-livebox::before {
  content: "\ea76";
}
.icon-my-media::before {
  content: "\ea77";
}
.icon-My-Office-Phone::before {
  content: "\ea78";
}
.icon-my-orange::before {
  content: "\ea79";
}
.icon-my-plug::before {
  content: "\ea7a";
}
.icon-my-services::before {
  content: "\ea7b";
}
.icon-my-travels::before {
  content: "\ea7c";
}
.icon-Nature::before {
  content: "\ea7d";
}
.icon-Network-add::before {
  content: "\ea7e";
}
.icon-Network-livebox::before {
  content: "\ea7f";
}
.icon-networks::before {
  content: "\ea80";
}
.icon-new::before {
  content: "\ea81";
}
.icon-newspaper::before {
  content: "\ea82";
}
.icon-nfc::before {
  content: "\ea83";
}
.icon-NFC-tag::before {
  content: "\ea84";
}
.icon-no-sim::before {
  content: "\ea85";
}
.icon-Notification::before {
  content: "\ea86";
}
.icon-Notification-Bell::before {
  content: "\ea87";
}
.icon-nouveau-contract-social::before {
  content: "\ea88";
}
.icon-numero-unique::before {
  content: "\ea89";
}
.icon-office::before {
  content: "\ea8a";
}
.icon-oil-and-gas::before {
  content: "\ea8b";
}
.icon-On-board-unit::before {
  content: "\ea8c";
}
.icon-online-help::before {
  content: "\ea8d";
}
.icon-orangewallet::before {
  content: "\ea8e";
}
.icon-orange-assistant::before {
  content: "\ea8f";
}
.icon-orange-cash::before {
  content: "\ea90";
}
.icon-orange-corporate::before {
  content: "\ea91";
}
.icon-Orange-Detente::before {
  content: "\ea92";
}
.icon-orange-events::before {
  content: "\ea93";
}
.icon-Orange-Experts-Class-Training::before {
  content: "\ea94";
}
.icon-Orange-Experts-Copy-Keep::before {
  content: "\ea95";
}
.icon-orange-experts-expert::before {
  content: "\ea96";
}
.icon-orange-experts-insurance::before {
  content: "\ea97";
}
.icon-Orange-Experts-One2One-Coach::before {
  content: "\ea98";
}
.icon-orange-experts-orange-business-consultant::before {
  content: "\ea99";
}
.icon-orange-experts-orange-startup-smartphone::before {
  content: "\ea9a";
}
.icon-orange-experts-recycle::before {
  content: "\ea9b";
}
.icon-orange-experts-repair::before {
  content: "\ea9c";
}
.icon-orange-experts-tranquil::before {
  content: "\ea9d";
}
.icon-Orange-Football-Club::before {
  content: "\ea9e";
}
.icon-orange-foundation::before {
  content: "\ea9f";
}
.icon-Orange-garden::before {
  content: "\eaa0";
}
.icon-orange-gesture::before {
  content: "\eaa1";
}
.icon-orange-jobs::before {
  content: "\eaa2";
}
.icon-orange-messenger::before {
  content: "\eaa3";
}
.icon-Orange-Messenger-1::before {
  content: "\eaa4";
}
.icon-orange-money::before {
  content: "\eaa5";
}
.icon-orange-Photography::before {
  content: "\eaa6";
}
.icon-orange-pro::before {
  content: "\eaa7";
}
.icon-orange-protect::before {
  content: "\eaa8";
}
.icon-orange-radio::before {
  content: "\eaa9";
}
.icon-orange-reflections::before {
  content: "\eaaa";
}
.icon-Orange-rewards::before {
  content: "\eaab";
}
.icon-Orange-security-suite::before {
  content: "\eaac";
}
.icon-orange-travel::before {
  content: "\eaad";
}
.icon-orange-tv::before {
  content: "\eaae";
}
.icon-orange-wallet::before {
  content: "\eaaf";
}
.icon-orange-wifi::before {
  content: "\eab0";
}
.icon-Orange-Guest::before {
  content: "\eab1";
}
.icon-organisation::before {
  content: "\eab2";
}
.icon-outbox::before {
  content: "\eab3";
}
.icon-outdoor-sports-mountain-biking::before {
  content: "\eab4";
}
.icon-outdoor-sports-skiing::before {
  content: "\eab5";
}
.icon-Package::before {
  content: "\eab6";
}
.icon-Package-content::before {
  content: "\eab7";
}
.icon-parabole-tv::before {
  content: "\eab8";
}
.icon-parental-control::before {
  content: "\eab9";
}
.icon-Parrasol::before {
  content: "\eaba";
}
.icon-pass-video::before {
  content: "\eabb";
}
.icon-Pause::before {
  content: "\eabc";
}
.icon-Payment-euro::before {
  content: "\eabd";
}
.icon-pda-device::before {
  content: "\eabe";
}
.icon-pdf-file::before {
  content: "\eabf";
}
.icon-Pencil::before {
  content: "\eac0";
}
.icon-personal-smartphone-trainer::before {
  content: "\eac1";
}
.icon-phone-advisor::before {
  content: "\eac2";
}
.icon-phone-fund::before {
  content: "\eac3";
}
.icon-phone-protection::before {
  content: "\eac4";
}
.icon-Photo-dialer::before {
  content: "\eac5";
}
.icon-picture-messaging::before {
  content: "\eac6";
}
.icon-Pills::before {
  content: "\eac7";
}
.icon-pioneering::before {
  content: "\eac8";
}
.icon-Play::before {
  content: "\eac9";
}
.icon-play-and-win::before {
  content: "\eaca";
}
.icon-playmode-random::before {
  content: "\eacb";
}
.icon-polls::before {
  content: "\eacc";
}
.icon-portfolio::before {
  content: "\eacd";
}
.icon-POS-payment::before {
  content: "\eace";
}
.icon-power::before {
  content: "\eacf";
}
.icon-powerpoint-file::before {
  content: "\ead0";
}
.icon-presentation::before {
  content: "\ead1";
}
.icon-previous::before {
  content: "\ead2";
}
.icon-printer::before {
  content: "\ead3";
}
.icon-profileringtone::before {
  content: "\ead4";
}
.icon-public-transport::before {
  content: "\ead5";
}
.icon-publish::before {
  content: "\ead6";
}
.icon-Quirky-stories::before {
  content: "\ead7";
}
.icon-Quiz::before {
  content: "\ead8";
}
.icon-Radiography::before {
  content: "\ead9";
}
.icon-ratings-stars::before {
  content: "\eada";
}
.icon-Real-time::before {
  content: "\eadb";
}
.icon-recommend::before {
  content: "\eadc";
}
.icon-record::before {
  content: "\eadd";
}
.icon-Record-1::before {
  content: "\eade";
}
.icon-recycle-device::before {
  content: "\eadf";
}
.icon-Redo::before {
  content: "\eae0";
}
.icon-relationship::before {
  content: "\eae1";
}
.icon-reload::before {
  content: "\eae2";
}
.icon-remote-control::before {
  content: "\eae3";
}
.icon-Remote-control-1::before {
  content: "\eae4";
}
.icon-remove-modifier::before {
  content: "\eae5";
}
.icon-Replay-boxed-arrow::before {
  content: "\eae6";
}
.icon-Reply-boxed-arrow::before {
  content: "\eae7";
}
.icon-Reply-curved-arrow::before {
  content: "\eae8";
}
.icon-report::before {
  content: "\eae9";
}
.icon-responsible::before {
  content: "\eaea";
}
.icon-Restaurant::before {
  content: "\eaeb";
}
.icon-restore-to-phone::before {
  content: "\eaec";
}
.icon-Restrictied-content::before {
  content: "\eaed";
}
.icon-rewards::before {
  content: "\eaee";
}
.icon-ringtones::before {
  content: "\eaef";
}
.icon-running::before {
  content: "\eaf0";
}
.icon-Scales::before {
  content: "\eaf1";
}
.icon-scan-ticket::before {
  content: "\eaf2";
}
.icon-School::before {
  content: "\eaf3";
}
.icon-Science::before {
  content: "\eaf4";
}
.icon-Scissors::before {
  content: "\eaf5";
}
.icon-sd-card::before {
  content: "\eaf6";
}
.icon-SD-card-1::before {
  content: "\eaf7";
}
.icon-search::before {
  content: "\eaf8";
}
.icon-Send::before {
  content: "\eaf9";
}
.icon-Send-to-person::before {
  content: "\eafa";
}
.icon-Server::before {
  content: "\eafb";
}
.icon-servers::before {
  content: "\eafc";
}
.icon-settings::before {
  content: "\eafd";
}
.icon-setup-and-repair::before {
  content: "\eafe";
}
.icon-setup-and-repair-cog::before {
  content: "\eaff";
}
.icon-setup-internet::before {
  content: "\eb00";
}
.icon-share::before {
  content: "\eb01";
}
.icon-Share-wifi::before {
  content: "\eb02";
}
.icon-shipping::before {
  content: "\eb03";
}
.icon-Shop::before {
  content: "\eb04";
}
.icon-shop-pin::before {
  content: "\eb05";
}
.icon-shop-subscriptions::before {
  content: "\eb06";
}
.icon-Shopping::before {
  content: "\eb07";
}
.icon-Show-front-back-camera::before {
  content: "\eb08";
}
.icon-Shower::before {
  content: "\eb09";
}
.icon-showroom-mobile-pro::before {
  content: "\eb0a";
}
.icon-signal-1-5::before {
  content: "\eb0b";
}
.icon-signal-2-5::before {
  content: "\eb0c";
}
.icon-signal-3-5::before {
  content: "\eb0d";
}
.icon-signal-4-5::before {
  content: "\eb0e";
}
.icon-signal-5-5::before {
  content: "\eb0f";
}
.icon-signal-boost::before {
  content: "\eb10";
}
.icon-Silent::before {
  content: "\eb11";
}
.icon-sim::before {
  content: "\eb12";
}
.icon-simple-mode::before {
  content: "\eb13";
}
.icon-Simplicity::before {
  content: "\eb14";
}
.icon-Skip-backward::before {
  content: "\eb15";
}
.icon-Skip-forward::before {
  content: "\eb16";
}
.icon-Sleep::before {
  content: "\eb17";
}
.icon-Smart-electric-meter::before {
  content: "\eb18";
}
.icon-Smart-heating-switch::before {
  content: "\eb19";
}
.icon-smart-home::before {
  content: "\eb1a";
}
.icon-smart-lightswitch::before {
  content: "\eb1b";
}
.icon-smart-powerstation::before {
  content: "\eb1c";
}
.icon-Smart-Sensor::before {
  content: "\eb1d";
}
.icon-smart-tap::before {
  content: "\eb1e";
}
.icon-smart-watch::before {
  content: "\eb1f";
}
.icon-social-network::before {
  content: "\eb20";
}
.icon-software-update::before {
  content: "\eb21";
}
.icon-Sport-channel::before {
  content: "\eb22";
}
.icon-Start-services::before {
  content: "\eb23";
}
.icon-startup-wizard::before {
  content: "\eb24";
}
.icon-stocks::before {
  content: "\eb25";
}
.icon-stop-secret::before {
  content: "\eb26";
}
.icon-Stop-video::before {
  content: "\eb27";
}
.icon-Storage-cloud::before {
  content: "\eb28";
}
.icon-store-locator::before {
  content: "\eb29";
}
.icon-Street::before {
  content: "\eb2a";
}
.icon-Stretcher::before {
  content: "\eb2b";
}
.icon-subscription-package::before {
  content: "\eb2c";
}
.icon-Sudoku::before {
  content: "\eb2d";
}
.icon-support::before {
  content: "\eb2e";
}
.icon-surveillance-camera::before {
  content: "\eb2f";
}
.icon-Syncronise::before {
  content: "\eb30";
}
.icon-target-location::before {
  content: "\eb31";
}
.icon-tarif::before {
  content: "\eb32";
}
.icon-tariff::before {
  content: "\eb33";
}
.icon-task-list::before {
  content: "\eb34";
}
.icon-Taxi::before {
  content: "\eb35";
}
.icon-team-connect::before {
  content: "\eb36";
}
.icon-temperature::before {
  content: "\eb37";
}
.icon-terms-conditions::before {
  content: "\eb38";
}
.icon-text-alerts::before {
  content: "\eb39";
}
.icon-Thumb-down::before {
  content: "\eb3a";
}
.icon-Thumb-up::before {
  content: "\eb3b";
}
.icon-tick::before {
  content: "\eb3c";
}
.icon-tick-circle::before {
  content: "\eb3d";
}
.icon-tickets::before {
  content: "\eb3e";
}
.icon-Top-10::before {
  content: "\eb3f";
}
.icon-top-up-dollar::before {
  content: "\eb40";
}
.icon-top-up-euro::before {
  content: "\eb41";
}
.icon-top-up-uk-bank::before {
  content: "\eb42";
}
.icon-touch-screen::before {
  content: "\eb43";
}
.icon-town::before {
  content: "\eb44";
}
.icon-townhall::before {
  content: "\eb45";
}
.icon-track-your-delivery::before {
  content: "\eb46";
}
.icon-track-your-order::before {
  content: "\eb47";
}
.icon-Trading::before {
  content: "\eb48";
}
.icon-trash::before {
  content: "\eb49";
}
.icon-tv-plus::before {
  content: "\eb4a";
}
.icon-TV-series::before {
  content: "\eb4b";
}
.icon-Undo::before {
  content: "\eb4c";
}
.icon-unique::before {
  content: "\eb4d";
}
.icon-unknown-file::before {
  content: "\eb4e";
}
.icon-unlimited-calls::before {
  content: "\eb4f";
}
.icon-unlocked::before {
  content: "\eb50";
}
.icon-Unmissable::before {
  content: "\eb51";
}
.icon-upload::before {
  content: "\eb52";
}
.icon-USB::before {
  content: "\eb53";
}
.icon-usb-key::before {
  content: "\eb54";
}
.icon-Value::before {
  content: "\eb55";
}
.icon-vehicle::before {
  content: "\eb56";
}
.icon-video::before {
  content: "\eb57";
}
.icon-video-file::before {
  content: "\eb58";
}
.icon-video-phone::before {
  content: "\eb59";
}
.icon-video-meeting2::before {
  content: "\eb5a";
}
.icon-Vinyl::before {
  content: "\eb5b";
}
.icon-vip-contact::before {
  content: "\eb5c";
}
.icon-virtual-gamepad::before {
  content: "\eb5d";
}
.icon-visual-voicemail::before {
  content: "\eb5e";
}
.icon-vod-portrait::before {
  content: "\eb5f";
}
.icon-volte::before {
  content: "\eb60";
}
.icon-volume-high::before {
  content: "\eb61";
}
.icon-volume-low::before {
  content: "\eb62";
}
.icon-volume-mid::before {
  content: "\eb63";
}
.icon-volume-none::before {
  content: "\eb64";
}
.icon-vowifi::before {
  content: "\eb65";
}
.icon-vvm-message-read-full::before {
  content: "\eb66";
}
.icon-Warehouse::before {
  content: "\eb67";
}
.icon-Warningimportant::before {
  content: "\eb68";
}
.icon-Warning-important::before {
  content: "\eb69";
}
.icon-web-cam::before {
  content: "\eb6a";
}
.icon-web-fax-widget::before {
  content: "\eb6b";
}
.icon-Wholesale-retail::before {
  content: "\eb6c";
}
.icon-Widget::before {
  content: "\eb6d";
}
.icon-widget-tour::before {
  content: "\eb6e";
}
.icon-wifi::before {
  content: "\eb6f";
}
.icon-wifi-0::before {
  content: "\eb70";
}
.icon-wifi-1::before {
  content: "\eb71";
}
.icon-wifi-2::before {
  content: "\eb72";
}
.icon-wps-pairing::before {
  content: "\eb73";
}
.icon-WPT::before {
  content: "\eb74";
}
.icon-zoom-in::before {
  content: "\eb75";
}
.icon-zoom-out::before {
  content: "\eb76";
}
.icon-popout-tech::before {
  content: "\e634";
}
.icon-frame-care::before {
  content: "\e640";
}
.icon-frame-family::before {
  content: "\e637";
}
.icon-frame-fun::before {
  content: "\e638";
}
.icon-frame-home::before {
  content: "\e639";
}
.icon-frame-money::before {
  content: "\e63a";
}
.icon-frame-on-the-move::before {
  content: "\e63b";
}
.icon-frame-tariff::before {
  content: "\e63c";
}
.icon-frame-tech::before {
  content: "\e63d";
}
.icon-frame-wellbeing::before {
  content: "\e63e";
}
.icon-frame-work::before {
  content: "\e63f";
}
.icon-popout-care::before {
  content: "\e62d";
}
.icon-popout-family::before {
  content: "\e62e";
}
.icon-popout-fun::before {
  content: "\e62f";
}
.icon-popout-home::before {
  content: "\e630";
}
.icon-popout-money::before {
  content: "\e631";
}
.icon-popout-on-the-move::before {
  content: "\e632";
}
.icon-popout-tariff::before {
  content: "\e633";
}
.icon-popout-wellbeing::before {
  content: "\e635";
}
.icon-popout-work::before {
  content: "\e636";
}
.icon-ic_Statistics::before {
  content: "\eb82";
}
.icon-frame-connectivity::before {
  content: "\eb78";
}
.icon-frame-my-customers::before {
  content: "\eb79";
}
.icon-frame-performance::before {
  content: "\eb7a";
}
.icon-frame-security::before {
  content: "\eb7b";
}
.icon-frame-teamwork::before {
  content: "\eb7c";
}
.icon-popout-connectivity::before {
  content: "\eb7d";
}
.icon-popout-my-customers::before {
  content: "\eb7e";
}
.icon-popout-performance::before {
  content: "\eb7f";
}
.icon-popout-security::before {
  content: "\eb80";
}
.icon-popout-teamwork::before {
  content: "\eb81";
}

// Using svg
[class*="svg-"] {
  line-height: 1;
}

[class*="svg-"]::after {
  display: inline-block;
  width: $spacer;
  height: $spacer;
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
}

.svg-delete {
  @include svg-delete($white);
}

.svg-checkbox-tick {
  @include svg-checkbox-tick();
}

.svg-warning-circle {
  @include svg-warning-circle(theme-color("info"));
}

.svg-menu {
  @include svg-menu($white);

  &:hover,
  &:focus,
  &:active,
  :hover > &,
  :focus > & {
    @include svg-menu($black);
  }
}

.svg-tick {
  @include svg-tick(theme-color("success"));
}

.svg-success {
  @extend %svg-success;
}

.svg-warning {
  @extend %svg-warning;
}

.svg-error {
  @extend %svg-danger;
}

.svg-info {
  @extend %svg-info;
}

/* rtl:begin:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "previous-next",
    "search"  : "previous",
    "replace" : "next"
  } ]
} */
.svg-arrow-previous {
  @include svg-arrow-previous($black);

  @include hover-focus-active() {
    @include svg-arrow-previous($white);
  }
}

.svg-arrow-next {
  @include svg-arrow-next($black);
}

/* rtl:end:options */

.svg-arrow-next.btn-primary {
  @include svg-arrow-next($white);

  @include active() {
    @include svg-arrow-next($black);
  }
}

.svg-settings {
  @include svg-settings($white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  :hover > &,
  :focus > & {
    @include svg-settings($orange-2);
  }
}

.svg-search {
  @include svg-search($white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  :hover > &,
  :focus > & {
    @include svg-search($orange-2);
  }
}

.svg-avatar {
  @include svg-avatar($white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  :hover > &,
  :focus > & {
    @include svg-avatar($orange-2);
  }
}

.svg-buy {
  @include svg-buy($white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  :hover > &,
  :focus > & {
    @include svg-buy($orange-2);
  }
}

.svg-mail {
  @include svg-mail($white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  :hover > &,
  :focus > & {
    @include svg-mail($orange-2);
  }
}

.svg-person {
  @include svg-person();
}
